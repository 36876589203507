import {
  Box,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import { PhoneIcon, EmailIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { ImGoogle } from "react-icons/im";
import { useState } from 'react';
import SigninCard from '../component/signincard.component';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectPendingProfile } from '../reducers';
import Profile from './profile.page';
import AddressForm from '../component/addressform.component';



const LoginPage = () => {

  const [isEnableGoogle, setIsEnableGoogle] = useState(false);
  const [showSignInComponent, setShowSignInComponent] = useState(false);
  const [tag, setTag] = useState('EMAIL ID')
  const [profileDeatils, setProfileDetails] = useState('');
  const pendingProfile = useSelector(selectPendingProfile);
  const [address, setAddress] = useState(null);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleOnClick = (text) => {
    setTag(text)
    setShowSignInComponent(true);
  }


  return (
    <Box bg="white" h="100vh" p={4}>
      <Flex direction="column" align="center" h="full">
        
        {!showSignInComponent && 
        < >
        <Text fontWeight='semibold' fontSize='2xl' width={{base: '70%', md: '25%'}}>What's your phone number or email?</Text>
        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width={{base: '70%',md: '25%'}}
          py={4}
          px={6}
          leftIcon={<EmailIcon margin='1'/>}
          onClick={() => handleOnClick('EMAIL ID')}
        >
          Sign In with Email
        </Button>

        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width={{base: '70%',md: '25%'}}
          py={4}
          px={6}
          leftIcon={<PhoneIcon margin='1'/>}
          onClick={() => handleOnClick('PHONE NUMBER')}
        >
          Sign In with Phone
        </Button>
        </>}
        {showSignInComponent && <SigninCard tag={tag}/>}
        {pendingProfile && isLoggedIn &&  <Profile updateValueText={setProfileDetails} screen_name='sigin_up' />}
        {!address && isLoggedIn && <AddressForm updateValueText={setAddress}/>}
        {isEnableGoogle && 
        <Box>
        <Text fontWeight='semibold'>or</Text>
        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width='25%'
          py={4}
          px={6}
          leftIcon={<ImGoogle style={{margin:'-1'}}/>}
        >
          Sign In with Google
        </Button>
        <Button 
          padding='2'
          margin='2'
          borderColor="gray.200"
          borderRadius="md"
          width='25%'
          py={4}
          px={6}
          leftIcon={<PhoneIcon margin='1'/>}
        >
          Sign In with Phone otp
        </Button>
        </Box>}
      </Flex>
    </Box>
  );
};

export default LoginPage;