import React, { useState } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectUserProfile } from '../reducers';
import { IoCheckbox } from "react-icons/io5";
import activityService from '../service/activity.service';



const wheelzBanner='https://res.cloudinary.com/dn0hjcpmq/image/upload/v1727979071/banner_wyaml7.png';
const carLuxeImg = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1726303356/qgfreqpzkqhygmpilmey.webp';
const scootyImg = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1728733458/r1kuiobtjdz1du9n30f6.png';
const bikeImg = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1728732994/xguxegxfmkbptcfhxv5n-removebg-preview_zcdwqn.png';
const bikeLuxeImg = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1728733380/ynfnm0vshcop8xsve4ke.png';
const carNormalImg = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1726303357/kw2j7bjrpiccriuzz4mu.webp';
const suvNormalImg = 'https://res.cloudinary.com/dn0hjcpmq/image/upload/v1726303356/kejmevuvbthlybkmx9ma.webp';

const carData = [
  {
    id: '1',
    title: 'Normal',
    img: carNormalImg,
    description: '',
    price: '699/-'
  },
  {
    id: '2',
    title: 'Suv',
    img: suvNormalImg,
    description: '',
    price: '799/-'
  },
  {
    id: '3',
    title: 'Luxe',
    img: carLuxeImg,
    description: '',
    price: '899/-'
  }
]

const bikeData = [
  {
    id: '1',
    title: 'Scooty',
    img: scootyImg,
    description: '',
    price: '299/-'
  },
  {
    id: '2',
    title: 'Bike',
    img: bikeImg,
    description: '',
    price: '399/-'
  },
  {
    id: '3',
    title: 'Luxe',
    img: bikeLuxeImg,
    description: '',
    price: '499/-'
  }
]


const ProductBookingPage = ({service}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate=useNavigate()
  const [selectedItem, setSelectedItem] = useState(null);
  const [enableTop, setEnableTop] = useState(true);
  const data = (service === 'car' || service === '') ? carData : bikeData;
  const userProfile = useSelector(selectUserProfile);

  const handleOnClick = (item) => {
    setSelectedItem(item);
    setEnableTop(false);
  };

  const hadleCheckout = (price) => {
    createActivity(price);
    if(isLoggedIn)
      navigate('/checkout', {state: {price: price}});
    else navigate('/login')
  }

  let createActivity = async (price) => {
      try {
          const obj = {
              user_id : userProfile.user_id,
              plan_id: service + price,
              date_of_purchase: new Date(),
              plan_start_date: new Date(),
              plan_type: 'Monthly',
              address_id: 'orderAddressId'
          }
          const result = await activityService.createActivity(obj);
          if(!result)
              throw new Error(`Error in creating order`);
      }catch(e) {
          console.error(`Some thing went wrong !\n` + e);
          alert(`Some thing went wrong !`);
      }
  }

  const handleArrowBack = () => {
    setEnableTop(true);
    setSelectedItem(false);
  }

  return (
    <Box maxH='100%' style={{ display: 'flex', width: '100%', height: '50%' }}>
      <div style={{ width: '40%' }}>
        <Image src={wheelzBanner} alt="Wheelz Banner" style={{ width: '100%' }} height={'100%'} width={'100%'} />
      </div>
      <div style={{ width: '60%', height: '100%', padding: '20px', textAlign: 'left' }}>
      <Text textAlign='center' fontWeight='bold' marginBottom='2' fontSize={{base: 'sm', md: 'md', lg: 'xl', xl: 'xl'}}>Product Offerings</Text>
      {enableTop && data.map((item, index) => (
        <Box
          key={item.id}
          cursor="pointer"
          opacity={1}
          _hover={{ opacity: 0.5 }}
          onClick={() => handleOnClick(item)}
          borderRadius="md"
          backgroundColor="gray.200"
          padding="4"
          border="1px solid"
          borderColor="gray.300"
          marginBottom="2"
          height="100%"
          overflow="hidden"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" justifyContent="normal">
              <Image 
                src={item.img} 
                alt="Car Luxe" 
                maxWidth={{ base: '40%',md:"20%"}} 
                maxHeight={{ base: '40%',md:"20%"}} 
                objectFit="contain" 
              />
              <Box>
                <Text fontWeight={'bold'} fontSize={{base: 'xs', md: 'md', lg: 'xl', xl: 'xl'}}>{item.title}</Text>
                <Text fontWeight={'semibold'} fontSize={{base: '2xs', md: 'md', lg: 'xl', xl: 'xl'}}>{item.description}</Text>
              </Box>
            </Flex>
            <Text fontWeight={'bold'} fontSize={{base: 'xs', md: 'md', lg: 'xl', xl: 'xl'}}>{item.price}</Text>
            <Box>
              <ArrowForwardIcon
                rounded={true}
                background="black"
                boxSize={{base:"3", md:"6"}}
                color="white"
                marginLeft={{base: "2",md:"10"}}
                borderRadius="full"
              />
            </Box>
          </Flex>
        </Box>
      ))}
      {selectedItem && (
        <Box >
          <Box
            marginBottom='4'          
            _hover={{ opacity: 0.5 }}
            opacity={1}

          >
            <Flex>
              <ArrowBackIcon
                borderRadius='full'
                fontSizeAdjust='md'
                size='20px'
                onClick={handleArrowBack}

              />
            </Flex>
          </Box>

          <Box borderBottom='1px solid gray.700' marginBottom='4'>
            <Text alignItems='center' textAlign='center' fontSize={{base: 'sm', md : 'md', lg:"xl", xl: "xl"}}  fontWeight='bold' marginBottom='4'>Selected Sevice: {selectedItem.title}</Text>
              <Flex justifyContent='space-between' padding={{base: '0', md: '2', lg: '4', xl: '4'}} flexDirection={{base: 'column', md: 'row'}}>
                <Text fontSize={{base: 'sm', md : 'md', lg:"xl", xl: "xl"}} fontWeight='bold' textColor='gray.700'>Exterior Cleaning</Text>
                <Text fontSize={{base: 'xs', md : 'sm', lg:"md", xl: "md"}}  textColor='black' fontWeight={'semibold'}>6 days in a week</Text>
              </Flex>
              <Flex justifyContent='space-between' padding={{base: '0', md: '2', lg: '4', xl: '4'}}   flexDirection={{base: 'column', md: 'row'}}>
                <Text fontSize={{base: 'sm', md : 'md', lg:"xl", xl: "xl"}} fontWeight='bold' textColor='gray.700'>Interior Cleaning</Text>
                <Text fontSize={{base: 'xs', md : 'sm', lg:"md", xl: "md"}}  textColor='black' fontWeight={'semibold'}>once a week</Text>
              </Flex>
              <Flex justifyContent='space-between' padding={{base: '0', md: '2', lg: '4', xl: '4'}}   flexDirection={{base: 'column', md: 'row'}}>
                <Text fontSize={{base: 'sm', md : 'md', lg:"xl", xl: "xl"}}  fontWeight='bold' textColor='gray.700'>Tracking</Text>
                <Text fontSize={{base: 'xs', md : 'sm', lg:"md", xl: "md"}}  textColor='black' fontWeight={'semibold'}>manage everything on your app</Text>
              </Flex>
              <Flex justifyContent='space-between' padding={{base: '0', md: '2', lg: '4', xl: '4'}} flexDirection={{base: 'column', md: 'row'}} >
                <Text fontSize={{base: 'sm', md : 'md', lg:"xl", xl: "xl"}} fontWeight='bold' textColor='gray.700'>Price</Text>
                <Text fontSize={{base: 'xs', md : 'sm', lg:"md", xl: "md"}} textColor='black' fontWeight={'semibold'}>{selectedItem.price} only per month</Text>
              </Flex>
              <Flex padding={{base: '0', md: '2', lg: '4', xl: '4'}} flexDirection={{base: 'row', md: 'row'}} >
                <IoCheckbox size={'10'} style={{ marginTop:'8' }}/>
                <Text fontSize={{base: '3xs', md : 'sm', lg:"sm", xl: "sm"}}>above order is booked on your default address</Text>
              </Flex>
          </Box>
          <Button 
              backgroundColor="black" 
              color="white"
              size="lg" 
              borderRadius="md" 
              padding="4" 
              marginTop='2'
              width="100%"
              onClick={() => hadleCheckout(selectedItem.price)}
              fontSize={{base: 'sm',  md: 'md', lg: 'xl', xl: 'xl'}}
            >
              Proceed to Checkout
            </Button>
        </Box>
      )}
      </div>
    </Box>
  );
};

export default ProductBookingPage;



/**
 * 
      <Button 
        backgroundColor="black" 
        color="white"
        size="lg" 
        borderRadius="md" 
        padding="4" 
        width="100%"
        onClick={() => console.log('Checkout clicked')}
      >
        Proceed to Checkout
      </Button>
 */