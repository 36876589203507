import React from 'react'
import { Box } from '@chakra-ui/react';
import BodyOne from '../component/body_1.component';
import BodyTwo from '../component/body_2.component';
import BodyThree from '../component/body_three.component';

export default function Landingpages() {
  return (
    <Box  maxWidth={'100%'} padding={0} margin={0}
    >
      <BodyOne />
      <BodyThree />
      <BodyTwo />
    </Box>
  )
}
