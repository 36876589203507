import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { searchServices } from "../utils/searchresult.utils";
import HomePageService from "../component/homepage.service";
import HomeTopSection from "../component/homepagetop.component";

const Home = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [scrollNav, setScrollNav] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setScrollNav(scrollY >= 1000 ? scrollY : 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!query) {
        setSuggestions([]);
        return;
      }
      const filteredServices = searchServices.filter((item) =>
        item.service.toLowerCase().includes(query)
      ).map((item) => item.service);
      setSuggestions(filteredServices);
      setTimeout(() => setLoading(false), 1000);
    };
    fetchSuggestions();
  }, [query]);

  return (
    <Box>
      <HomeTopSection
        loading={loading}
        onChange={(val) => setQuery(val)}
        setLoading={setLoading}
        suggestions={suggestions}
      />
      <HomePageService scrollNav={scrollNav} />
    </Box>
  );
};

export default Home;