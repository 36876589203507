import { TimeIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentConfirmation = () => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate('/')
    }

    return (
        <Box>
            <Box textAlign='center' margin='4'>
                <Text fontWeight='bold'>Payment Page</Text>
            </Box>
            <Box
                padding='6'
            >
                <TimeIcon
                    boxSize='18%'
                />
                <Box fontWeight='bold' textAlign='center' padding='4'>
                    <Text>Pending</Text>
                    <Text>We're verifying your payment. This may take a few moments.</Text>
                </Box>

            <Button 
                backgroundColor="black" 
                color="white"
                size="lg" 
                borderRadius="md" 
                padding="6"
                margin='4' 
                marginTop='2'
                width="80%"
                onClick={() => handleOnClick()}
            >
                Return Home
            </Button>
            </Box>
        </Box>
    )
}

export default PaymentConfirmation;