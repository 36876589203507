import {
    Box,
    Flex,
    Heading,
    Text,
    Link,
    VStack,
    Container
  } from '@chakra-ui/react';
  
  const PrivacyPolicy = () => {
    return (
      <Container maxW="container.xl" py={12}>
        <Box marginBottom={8}>
          <Heading as="h1" size="xl" mb={4} textAlign="center">
            Privacy Policy
          </Heading>
          <Text fontSize="lg" mb={4} textAlign='left'>
            Amarnath Bhakat operates wheelz365.com. This page informs website
            visitors about our policies regarding the collection, use, and disclosure
            of Personal Information.
            If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
          </Text>
        </Box>
        <Flex height="100vh" overflow="hidden">
      <Box
        w="15%"
        minWidth="15%"
        marginRight={4}
        height="100vh"
        textAlign="left"
        overflow="auto"
        position="sticky"
        top={0}
        left={0}
        bg="white"
        zIndex={1}
        padding={4}
      >
        <VStack spacing={4} alignItems="flex-start">
          <Link href="#information-collection">Information Collection and Use</Link>
          <Link href="#log-data">Log Data</Link>
          <Link href="#cookies">Cookies</Link>
          <Link href="#childrens-privacy">Children's Privacy</Link>
          <Link href="#changes-to-policy">Changes to This Privacy Policy</Link>
          <Link href="#security">Security</Link>
          <Link href="#service-providers">Service Providers</Link>
          <Link href="#contact-us">Contact Us</Link>
        </VStack>
      </Box>

      <Box
        flex={1}
        textAlign="left"
        width="85%"
        height="100vh"
        overflow="auto"
        padding={4}
      >
          <Section id="information-collection" title="Information Collection and Use">
            <Text fontSize="lg" mb={4}>
            For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.
            </Text>
            <Text fontSize="lg" mb={4}>
            Once we assign the job to a cleaner and start the job, we need a cleaner location in the foreground and background to keep track of the cleaner and show the cleaner’s location to our customer and support team. Then, if there are any issues, customers and cleaners can contact our support team, and we can stay up to date with all things going on in operation.
            </Text>
          </Section>
          <Section id="log-data" title="Log Data">
            <Text fontSize="lg" mb={4}>
            We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
            </Text>
          </Section>

          <Section id="cookies" title="Cookies">
            <Text fontSize="lg" mb={4}>
            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive
            </Text>
            <Text fontSize="lg" mb={4}>
            Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.
            </Text>
          </Section>

          <Section id="childrens-privacy" title="Children's Privacy">
            <Text fontSize="lg" mb={4}>
            Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
            </Text>
          </Section>

          <Section id="changes-to-policy" title="Changes to This Privacy Policy">
            <Text fontSize="lg" mb={4}>
            We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
            </Text>
          </Section>

        <Section id="security" title="Security">
            <Text fontSize="lg" mb={4}>
                We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.       
            </Text>
        </Section>

        <Section id="service-providers" title="Service Providers">
            <Text fontSize="lg" mb={4}>
                We may employ third-party companies and individuals due to the following reasons:
                To facilitate our Service.
                To provide the Service on our behalf.
                To perform Service-related services
                To assist us in analyzing how our Service is used.
                We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
            </Text>
        </Section>

          <Section id="contact-us" title="Contact Us">
            <Text fontSize="lg" mb={4}>
              Contact us with questions or suggestions about our Privacy Policy.
            </Text>
          </Section>
      </Box>
    </Flex>

      </Container>
    );
  };
  
  const Section = ({ id, title, children }) => {
    return (
      <Box marginBottom={8}>
        <Heading as="h2" size="lg" mb={4} id={id}>
          {title}
        </Heading>
        {children}
      </Box>
    );
  };
  
  export default PrivacyPolicy;