import React from "react";
import "../style/Blog.css";
import { Box, Image, Text } from "@chakra-ui/react";
import '../style/body_1.css';


const Blog = () => {
  return (

    <Box alignContent={'center'} height={'60vh'}>
      <Text fontWeight={'bold'} mb={10}>New Products</Text>
      <Text fontSize={{base: 'xl', md: '100px'}} fontWeight={'bold'}>Coming Soon !</Text>
      <Text fontWeight={'bold'} mt={10}>@Wheelz 365.com</Text>
    </Box>
  );
};

export default Blog;
