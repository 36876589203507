import './App.css';
import Mainroutes from './routes/main.routes';

function App() {
  return (
    <div className="App">
        <Mainroutes />
    </div>
  );
}

export default App;
