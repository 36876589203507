import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Text } from '@chakra-ui/react';
import TextBox from '../component/textbox.component';
import { selectUserProfile } from '../reducers';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';

let screenConfig = {
  normal_phone : {
      regex: /^\d{10}$/,
      errorMessage: 'Invalid phone number'
  },
  normal_password : {
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      errorMessage: 'Password must be at least 8 characters, including uppercase, lowercase, digits, and special characters'
  },
  normal_email : {
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      errorMessage: 'Invalid email address'
  },
  refferal_code : {
      regex: '',
      errorMessage: 'Refferal code not found'
  }
}



const Profile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = useSelector(selectUserProfile);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  // const [veichleNo, setVeichleNo] = useState('');
  const [updatePending, setUpdatePending] = useState(true);
  const route = useLocation();
  const screen_name = props.screen_name || 'sigin_up';
  
  let updateData = () => {
    if('updateValueText' in props)
      props.updateValueText({email_id: email || props.param2, phone_no: phoneNo || props.param3, user_name: name})
  }

  const handleOnClick = () => {
    navigate('/address')
  }

  useEffect(() => {
    console.log(screen_name);
    updateData();
  }, [name, email, phoneNo])


  return (
    <Box className='bg-white h-full p-6' alignContent='center' h={{base:'45vh', md: '50vh', lg: '60vh', xl: '60vh'}}>
        <Text fontWeight={'bold'} m='2' fontSize={{base: 'xl', md: 'xl', lg: '2xl', xl: '3xl'}}>User Profile</Text>
        <Box className='bg-white h-full p-6' display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box padding={4} width={{base:'70%', md: '70%', lg: '30%', xl: '30%'}} margin={'auto'}>
                <TextBox param1='NAME' param2='Enter your name' param3={props.param1} 
                param4={'' || userProfile?.user_name}
                udpateTextValue={setName}
                />
                <TextBox param1='EMAIL ADDRESS' param2='Enter your email address' param3={props.param1}
                param4={props.param2 || '' || userProfile?.email} param5={screenConfig.normal_email} param6={props.param4}
                udpateTextValue={(text) => setEmail(text || props.param2)}
                />
                <TextBox param1='PHONE NUMBER' param2='Enter your phone number' param3={props.param1}
                param4={props.param3 || '' || userProfile?.user_phone_no} param5={screenConfig.normal_phone} param6={props.param5}
                udpateTextValue={(text) => setPhoneNo(text || props.param3)}
                /> 
            </Box>
        </Box>
        {screen_name === 'sigin_up' && <Button 
            onClick={() => handleOnClick()} 
            colorScheme="gray.400"
            backgroundColor={'gray.400'}
            rightIcon={<ArrowForwardIcon mt={-0.2} />}
            borderRadius={'full'}
            mr={3}
        />}
      {/* <TextBox param1='VEHICLE NUMBER' param2='Enter your vehicle number' param3={props.param1}
        param4={''}
        udpateTextValue={setVeichleNo}
      /> */}
    </Box>
  );
};

export default Profile;